<template>
  <div class="login_contain">
    <div class="login_box">
      <div class="login_tit">LOT数据解析平台</div>
      <div class="login_cont">
        <div class="login_form">
          <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
            <el-form-item prop="name">
              <el-input type="text" class="name" placeholder="请输入账号" auto-complete="off" v-model="loginForm.account" @keyup.enter.native="submitForm"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" class="password" placeholder="请输入密码" auto-complete="off" v-model="loginForm.password" @keyup.enter.native="submitForm"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="loginBtn" @click.native.prevent="submitForm">立即登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userLogin } from '@/api/port'
export default {
  data () {
    return {
      loginForm: {
        account: '',
        password: ''
      },
      loginRules: {
        account: [
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ],
        password: [
          { min: 6, max: 18, message: '密码长度在 6 到 18 个字符', trigger: 'blur'}
        ]
      },
    }
  },
  created () {
  },
  methods: {
    submitForm () {
      this.$refs.loginForm.validate(valid => {
        if (!valid) return
        const params = {
          account: this.loginForm.account,
          password: this.loginForm.password
        }
        userLogin(params).then(res => {
          if (res.status === 1) {
            window.localStorage.setItem('user', JSON.stringify(res.data))
            this.$router.push({ path: '/main' })
          } else {
            this.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.login_contain {
  width: 100%;
  height: 100%;
  position: relative;
  background: url(../../assets/img/bg.png) no-repeat;
  background-size: 100% 100%;
}
.login_box {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -250px;
  margin-left: -320px;
  overflow: hidden;
}
.login_tit {
  width: 100%;
  font-size: 50px;
  text-align: center;
  font-family: 'PingFang SC-Heavy, PingFang SC;';
  font-weight: 800;
  color: #FFFFFF;
  letter-spacing: 14px;
  text-indent: 14px;
}
.login_form {
  position: relative;
  margin: 40px auto 0px;
  padding: 20px;
  box-sizing: border-box;
  width: 530px;
  height: 390px;
  background: rgba(21,28,51,0.9020);
  border-radius: 8px;
  filter: blur(undefinedpx);
}
.login_form .el-form {
  padding: 30px;
}
.name >>> .el-input__inner, .password >>> .el-input__inner, .loginBtn {
  width: 100%;
  height: 60px;
  background: rgba(7, 66, 143, 0.1020);
  /* box-shadow: inset 0px 3px 6px 1px rgb(0, 0, 0, 0.05882); */
  border-radius: 8px;
  border: 1px solid #92aac9;
  padding-left: 30px;
  color: #92aac9;
}
.password >>> .el-input__inner {
  margin-top: 15px;
}
.name ::placeholder, .password ::placeholder{
  font-size: 18px;
  font-family: 'PingFang SC-Medium, PingFang SC;';
  font-weight: 500;
  line-height: 23px;
  color: #92aac9;
}
.loginBtn {
  margin-top: 35px;
  font-size: 18px;
  font-family: 'PingFang SC-Medium, PingFang SC';
  font-weight: 500;
  line-height: 23px;
  color: #FFFFFF;
  cursor: pointer;
  background: #5473E8;
  letter-spacing: 2px;
}
</style>
